<template>
	<div id="userList">
		<div class="content-table">
			<el-table :data="tableData.list" border style="width: 100%" :max-height="screenHeight">
				<el-table-column align="center" prop="name" label="姓名"></el-table-column>
				<el-table-column align="center" label="头像">
					<template slot-scope="scope">
						<img :src="scope.row.avatar" width="100" height="100" />
					</template>
				</el-table-column>
				<el-table-column align="center" prop="phone" label="手机"></el-table-column>
				<el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
				<el-table-column align="center" fixed="right" label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="primary" round size="small" @click="userEdit(scope)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-pagination @size-change="sizeChange" @current-change="pageChange" :current-page="params.page"
			:page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next"
			:total="tableData.totalCount" class="pagination"></el-pagination>
	</div>
</template>

<script>
	import {
		loadMemeberList
	} from '@/api/index'
	import CONFIG from '@/utils/config';
	export default {
		name: "userList",
		created() {
			this.loadMemeberList();
		},
		data() {
			return {
				params: {
					page: 1,
					size: 10,
					searchKey: ''
				},
				tableData: {
					list: []
				}
			}
		},
		computed: {
			screenHeight: function() {
				let screenHeight = this.$getSystemInfo().screenHeight;
				let height = screenHeight - 60 - 0.06 * screenHeight - 60;
				return height
			}
		},
		methods: {
			loadMemeberList: async function() {
				let params = this.params;
				let result = await loadMemeberList(params);
				if (result.status == 1) {
					this.tableData = result.data;
				}
			},
			userEdit: function(scope) {
				console.log(scope);
				this.$router.push({
					name: CONFIG.ROUTENAMES.USEREDIT,
					query: {
						id: scope.row.userId
					}
				});
			},
			sizeChange(val) {
				this.params.size = val;
				this.params.page = 1;
				this.loadMemeberList();
			},
			pageChange(val) {
				this.params.page = val;
				this.loadMemeberList();
			},
		}
	};
</script>

<style lang="scss">
	#userList {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.content-table {
			width: 96%;
			margin-top: 2%;
		}

		.pagination {
			width: 96%;
			margin-top: 2%
		}
	}
</style>