const state = {
	device_type: 1,//1-浏览器 2-mobile
}

const mutations = {
	SET_DEVICE_TYPE: (state, st) => {
		state.device_type = st
	}
}

const actions = {
	setDeviceType({
		commit
	}, st) {
		commit('SET_DEVICE_TYPE', st)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}