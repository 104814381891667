import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from 'element-ui';

import CONFIG from '@/utils/config';
import JsonExcel from "vue-json-excel";

import 'element-ui/lib/theme-chalk/index.css';
import '@/style/index.scss';

import "./permission";

Vue.use(Element)


Vue.config.productionTip = false;

store.dispatch('device/setDeviceType',navigator.userAgent.match(CONFIG.deviceMatch) ? 2 : 1);//1-pc端 2-mobile端


Vue.component("downloadExcel", JsonExcel);

Vue.prototype.$getSystemInfo = function(){
	return{
		screenWidth:window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
		screenHeight:window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
	}
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
