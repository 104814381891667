<template>
	<div id="financial" v-loading.fullscreen="loading" element-loading-text="拼命加载中"
		element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
		<div class="pickerWrap">
			<span>当前月份：</span>
			<el-date-picker v-model="month" value-format="yyyy-MM" @change="monthChange" type="month"
				placeholder="请选择月份">
			</el-date-picker>
			<download-excel style="float: right;" :data="json_data" :fields="json_fields" worksheet="My Worksheet"
				:name="excelName">
				<el-button class="search-btn" type="primary">工资下载</el-button>
			</download-excel>
		</div>
		<div class="content-table">
			<el-table :data="tableData.list" border style="width: 100%" :max-height="screenHeight">
				<el-table-column align="center" prop="name" label="姓名"></el-table-column>
				<el-table-column align="center" prop="phone" label="手机"></el-table-column>
				<el-table-column align="center" label="总工资">
					<template slot-scope="scope"><span
							style="color:crimson">{{scope.row.userTotalSalary}}</span></template>
				</el-table-column>
				<el-table-column align="center" label="已结算">
					<template slot-scope="scope"><span
							style="color:blue">{{scope.row.userSettledSalary}}</span></template>
				</el-table-column>
				<el-table-column align="center" label="未结算">
					<template slot-scope="scope"><span
							style="color:crimson">{{scope.row.userSettlingSalary}}</span></template>
				</el-table-column>
				<!-- 	<el-table-column align="center" fixed="right" label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="primary" round size="small" @click="userEdit(scope)">详情</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</div>
		<!-- 		<el-pagination @size-change="sizeChange" @current-change="pageChange" :current-page="params.page"
			:page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next"
			:total="tableData.totalCount" class="pagination"></el-pagination> -->
	</div>
</template>

<script>
	import {
		loadCompanySalarySumBymonth
	} from '@/api/index';
	import {
		gouCurrentTime,
		showMonthFirstDayByMonth,
		showMonthLastDayByMonth
	} from '@/utils/util';
	export default {
		name: "financial",
		data() {
			return {
				month: '',
				tableData: {
					list: []
				},
				json_fields: {
					姓名: 'name',
					手机号码: 'phone',
					总工资: 'userTotalSalary',
					已结算: 'userSettledSalary',
					未结算: 'userSettlingSalary',
					月份: 'month'
				},
				json_data: [],
				excelName: ''
			}
		},
		computed: {
			screenHeight: function() {
				let screenHeight = this.$getSystemInfo().screenHeight;
				let height = screenHeight - 60 - 40 - 0.04 * screenHeight - 60;
				return height
			}
		},
		created() {
			this.month = `${gouCurrentTime().year}-${gouCurrentTime().month}`;
			this.excelName = `${this.month}.xls`;
			this.loadCompanySalarySumBymonth();
		},
		methods: {
			loadCompanySalarySumBymonth: async function() {
				this.loading = true;
				let params = {
					startTime: `${showMonthFirstDayByMonth(this.month)} 00:00:00`,
					endTime: `${showMonthLastDayByMonth(this.month)} 23:59:59`,
				};
				let result = await loadCompanySalarySumBymonth(params);
				if (result.status == 1) {
					let tableData = this.tableData;
					tableData.list = result.data.userList;
					this.tableData = tableData;
					this.json_data = result.data.userList;
				} else {
					this.$message({
						message: result.message,
						type: 'error',
						offset: 100
					});
				}
				this.loading = false;
				console.log(result, this.month);
			},
			monthChange: function(e) {
				this.month = e;
				this.tableData = {
					list: []
				}
				this.excelName = `${e}.xls`;
				this.loadCompanySalarySumBymonth();
			}
		}
	};
</script>

<style lang="scss">
	.el-loading-spinner {}

	#financial {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.pickerWrap {
			width: 96%;
			margin-top: 2%;
		}

		.content-table {
			width: 96%;
			margin-top: 2%;
		}

		.pagination {
			width: 96%;
			margin-top: 2%;
		}
	}
</style>