import SERVICE from '@/utils/service';
import {
	getToken
} from '@/utils/auth';

export function login(data){
	return SERVICE({
	  url: '/api1/login',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data
	})
}
export function loadPermission(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/loadPermission',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function loadCompanyProduct(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/loadCompanyProduct',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function loadUserInfo(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/loadUserInfo',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function loadMemeberList(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/loadMemeberList',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function loadMemeberDetail(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/loadMemeberDetail',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function editUser(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/editUser',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function loadLabelClass(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/loadLabelClass',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function loadLabelDetail(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/loadLabelDetail',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function loadCompanySalarySumBymonth(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/loadCompanySalarySumBymonth',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function loadUserCompanyPermission(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/loadUserCompanyPermission',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function updateUserCompanyPermission(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/updateUserCompanyPermission',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function loadCompanyCropList(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/loadCompanyCropList',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function loadCompanyRecordOperateList(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/loadCompanyRecordOperateList',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}
export function loadCompanyAmountCropList(data){
	let access_token = getToken();
	let params = data||{};
	params.access_token = access_token;
	return SERVICE({
	  url: '/api1/loadCompanyAmountCropList',
	  method: 'post',
	  headers: {
	    'content-Type': 'application/json'
	  },
	  data:params
	})
}











