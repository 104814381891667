import router from '@/router';
import store from '@/store';
// import CONFIG from '@/utils/config';
import {
	getToken,
	getPermission
} from '@/utils/auth';

router.beforeEach(async (to, from, next) => {
	console.log("beforeEach0", from);
	document.title = to.meta.title
	if (getToken()) { //检测是否登录
		console.log("beforeEach1", to);
		if (to.path === '/login') {
			console.log("beforeEach3", to);
			next('/');
		} else {
			console.log("beforeEach4", store.getters.token);
			if (store.getters.token) {
				console.log("beforeEach5", to);
				next();
			} else {
				console.log("beforeEach6", to);
				if (getPermission()) {
					const dynamicRoutes = await store.dispatch('permission/generateRoutes',
						getPermission());
					await store.dispatch('user/setToken', getToken());
					let existedRoutes = router.getRoutes();
					console.log('existedRoutes', existedRoutes)
					// for (let item of existedRoutes) {
					// 	if (item.name == CONFIG.ROUTENAMES.MAIN || item.name == CONFIG.ROUTENAMES
					// 		.LOGIN || item.name == CONFIG.ROUTENAMES.MAINHOME)
					// 		continue;
					// 	router.removeRoute(item.name);
					// }
					dynamicRoutes.forEach(res => {
						router.addRoute(res)
					})
					next({
						...to,
						replace: true
					})
				} else {
					if (to.path === '/login') {
						next();
					} else {
						next('/login')
					}
				}
			}
		}
	} else {
		console.log("beforeEach2", to);
		if (to.path === '/login') {
			next();
		} else {
			next('/login')
		}
	}
})

router.afterEach(() => {})