import {
	staticRoutes,
	dynamicRoutes
} from '@/router'
import CONFIG from '@/utils/config';

const filterAsyncRoutes = function(PERMISSION) {
	let dynamic = [];
	// if (PERMISSION.goodsManage == 1)
	// 	dynamic.push(CONFIG.ROUTENAMES.PRODUCTMANAGE);
	if (PERMISSION.financeManage == 1)
		dynamic.push(CONFIG.ROUTENAMES.FINANCIALMANAGE);
	// if (PERMISSION.cropManage == 1)
	// 	dynamic.push(CONFIG.ROUTENAMES.CROPMANAGE);
	// if (PERMISSION.cropRecord == 1)
	// 	dynamic.push(CONFIG.ROUTENAMES.CROPRECORD);
	if (PERMISSION.userManage == 1) {
		dynamic.push(CONFIG.ROUTENAMES.USERLIST);
		dynamic.push(CONFIG.ROUTENAMES.USEREDIT);
	}
	// if (PERMISSION.recordOperate == 1) {
	// 	dynamic.push(CONFIG.ROUTENAMES.RECORDMANAGE);
	// }
	if (PERMISSION.amountManage == 1) {
		dynamic.push(CONFIG.ROUTENAMES.CHECKMANAGE);
	}
	
	console.log('dynamic', dynamic, dynamicRoutes)
	// dynamic.push(CONFIG.ROUTENAMES.OTHERS);
	let dynamicRoutesCopy = [];
	for (let item of dynamicRoutes) {
		let tmp = {
			...item
		};
		if (tmp.children) {
			tmp.children = tmp.children.filter(function(a) {
				return dynamic.indexOf(a.name) > -1
			})
		}
		dynamicRoutesCopy.push(tmp)
	}
	let res = dynamicRoutesCopy.filter(function(a) {
		return !a.children || a.children.length > 0
	})
	return res;
}

const state = {
	routes: []
}

const mutations = {
	SET_ROUTES: (state, routes) => {
		let routes1 = [...staticRoutes, ...routes].filter(function(a) {
			return !a.hidden
		})
		let routes2 = [];
		for (let item of routes1) {
			let tmp = {
				...item
			}
			if (tmp.children) {
				tmp.children = tmp.children.filter(function(a) {
					return !a.hidden
				})
			}
			routes2.push(tmp)
		}
		state.routes = routes2
	}
}

const actions = {
	generateRoutes({
		commit
	}, PERMISSION) {
		return new Promise(resolve => {
			let accessedRoutes = filterAsyncRoutes(PERMISSION.companyPermission);
			commit('SET_ROUTES', accessedRoutes)
			resolve(accessedRoutes)
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}