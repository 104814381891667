<template>
	<div id="layout" v-if="device_type == 1">
		<Sliderbar></Sliderbar>
		<NavBar :userInfo="USERINFO"></NavBar>
		<div class="appmain">
			<router-view />
		</div>
	</div>
	<div id="layout" v-else-if="device_type == 2">
		<Sliderbar></Sliderbar>
		<div class="appmain_mobile">
			<router-view />
		</div>
	</div>
</template>

<script>
	import Sliderbar from "@/components/Sliderbar";
	import NavBar from '@/components/NavBar';
	import {
		mapGetters
	} from 'vuex';
	import {
		getUserInfo
	} from '@/utils/auth';
	export default {
		name: "layout",
		components: {
			Sliderbar,
			NavBar
		},
		data() {
			return {
				USERINFO: null
			}
		},
		computed: {
			...mapGetters([
				'device_type'
			])
		},
		created() {
			this.getUserInfo();
		},
		methods: {
			getUserInfo: async function() {
				this.USERINFO = getUserInfo();
			}
		}
	};
</script>
<style lang="scss">
	@import "@/style/common.scss";

	#layout {
		.appmain {
			margin-left: 210px;
			margin-top: $-main-navbar-height;
		}

		.appmain_mobile {}
	}
</style>