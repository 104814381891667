import {
	resetRouter
} from '@/router';

const state = {
	token: ''
}

const mutations = {
	SET_TOKEN: (state, token) => {
		state.token = token
	}
}

const actions = {
	setToken({
		commit
	}, token) {
		return new Promise(resolve => {
			commit('SET_TOKEN', token)
			resolve()
		})
	},
	loginout({
		commit
	}) {
		return new Promise(resolve => {
			commit('SET_TOKEN', '');
			resetRouter();
			resolve()
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}