import {
	isEmpty
} from "@/utils/util";
import CONFIG from '@/utils/config';

export function setToken(token) {
	localStorage.setItem(CONFIG.STORAGEKEY.LOGININFO, JSON.stringify({
		token,
		expire: new Date().getTime() + CONFIG.tokenExpire
	}))
}
export function getToken() {
	let token = JSON.parse(localStorage.getItem(CONFIG.STORAGEKEY.LOGININFO));
	if (isEmpty(token)) {
		return false;
	} else {
		if (new Date().getTime() > token.expire) {
			return false;
		} else {
			return token.token;
		}
	}
}
export function setPermission(data) {
	localStorage.setItem(CONFIG.STORAGEKEY.PERMISSION, JSON.stringify(data))
}
export function getPermission() {
	let PERMISSION = JSON.parse(localStorage.getItem(CONFIG.STORAGEKEY.PERMISSION));
	if (isEmpty(PERMISSION)) {
		return false;
	} else {
		return PERMISSION;
	}
}
export function setUserInfo(data) {
	localStorage.setItem(CONFIG.STORAGEKEY.USERINFO, JSON.stringify(data))
}
export function getUserInfo() {
	let USERINFO = JSON.parse(localStorage.getItem(CONFIG.STORAGEKEY.USERINFO));
	return USERINFO;
}
