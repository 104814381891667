import axios from 'axios';

const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 30000
})

service.interceptors.request.use(
	config => {
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	response => {
		const res = response.data
		return Promise.resolve(res)
	},
	error => {
		return Promise.resolve({
			status: 0,
			data: {},
			message: error
		})
	}
)

export default service;