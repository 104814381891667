<template>
	<div id="cropManage">
		<div class="content-table">
			<el-table :data="tableData.list" border style="width: 100%">
				<el-table-column align="center" label="商品图">
					<template slot-scope="scope">
						<img :src="scope.row.goodsUrl" width="100" height="100" />
					</template>
				</el-table-column>
				<el-table-column align="center" prop="cropNo" label="床号(刀号)"></el-table-column>
				<el-table-column align="center" label="商品数量">
					<template slot-scope="scope">
						<span>{{scope.row.goodsList.length}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="类型">
					<template slot-scope="scope">
						<span v-if="scope.row.orderStatus == 1" style="color:violet;">订单</span>
						<span v-else style="color:lightseagreen;">自裁</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="裁剪数量">
					<template slot-scope="scope">
						<span>{{scope.row.totalQuantity}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="状态">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 1" style="color:crimson;">领货中</span>
						<span v-else-if="scope.row.status == 2" style="color:chocolate;">待交货</span>
						<span v-else-if="scope.row.status == 3" style="color:blue;">待收货</span>
						<span v-else-if="scope.row.status == 4" style="color:violet;">待完工</span>
						<span v-else-if="scope.row.status == 5" style="color:lightseagreen;">已完工</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="进度">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 1" style="color:crimson;">{{scope.row.percent}}%</span>
						<span v-else-if="scope.row.status == 2"
							style="color:chocolate;">{{scope.row.percent}}%</span>
						<span v-else-if="scope.row.status == 3"
							style="color:blue;">{{scope.row.percent}}%</span>
						<span v-else-if="scope.row.status == 4"
							style="color:violet;">{{scope.row.percent}}%</span>
						<span v-else-if="scope.row.status == 5"
							style="color:lightseagreen;">{{scope.row.percent}}%</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="车缝">
					<template slot-scope="scope">
						<span v-for="item in scope.row.companyList" :key="item.id">{{item.name}};</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
				<el-table-column align="center" fixed="right" label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="primary" round size="small" @click="userEdit(scope)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-pagination @size-change="sizeChange" @current-change="pageChange" :current-page="params.page"
			:page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next"
			:total="tableData.totalCount" class="pagination"></el-pagination>
	</div>
</template>

<script>
	import {
		loadCompanyCropList
	} from '@/api/index'
	import {
		prefixAssetImage,
		isEmpty
	} from '@/utils/util';
	export default {
		name: "cropManage",
		data() {
			return {
				params: {
					page: 1,
					size: 10,
					searchKey: ''
				},
				tableData: {
					list: []
				}
			}
		},
		created() {
			this.loadCompanyCropList();
		},
		methods: {
			sizeChange(val) {
				this.params.size = val;
				this.params.page = 1;
				this.loadCompanyCropList();
			},
			pageChange(val) {
				this.params.page = val;
				this.loadCompanyCropList();
			},
			loadCompanyCropList: async function() {
				let params = this.params;
				let result = await loadCompanyCropList(params);
				if (result.status == 1) {
					for (let item of result.data.list) {
						item.goodsUrl = null;
						if (!isEmpty(item.goodsList[0].goodsCloudUrls))
							item.goodsUrl = prefixAssetImage(item.goodsList[0].goodsCloudUrls)
					}
					this.tableData = result.data;
				}
			}
		}
	};
</script>

<style lang="scss">
	#cropManage {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.content-table {
			width: 96%;
			margin-top: 2%;
		}

		.pagination {
			width: 96%;
			margin: 2% 0;
		}
	}
</style>