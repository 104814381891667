<template>
	<div id="userEdit">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="基础信息" name="base">
				<el-form v-if="userInfo" ref="form" :model="userInfo" label-position="left" label-width="80px">
					<el-form-item label="员工手机">
						<el-input v-model="userInfo.phone" disabled></el-input>
					</el-form-item>
					<el-form-item label="员工姓名">
						<el-input v-model="userInfo.nickName"></el-input>
					</el-form-item>
					<el-form-item label="员工工种"></el-form-item>
					<el-form-item label-width="0">
						<div v-for="item in labelList" :key="item.groupId">
							<el-checkbox v-model="item.all" @change="handleCheckAllChange(item)"><span
									style="color:crimson;">{{item.all ? '全不选' : '全选'}}({{item.groupName}})</span></el-checkbox>
							<el-checkbox-group v-model="userInfo.labelList">
								<el-checkbox v-for="label in item.labelList" :label="label.itemId"
									:key="label.itemId">{{label.itemName}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</el-form-item>
					<el-button type="primary" @click="editUser">保存</el-button>
				</el-form>
			</el-tab-pane>
			<el-tab-pane label="权限配置" name="right">
				<el-form v-if="userInfo" ref="form" :model="userInfo" label-position="left" label-width="80px">
					<el-form-item label="员工手机">
						<el-input v-model="userInfo.phone" disabled></el-input>
					</el-form-item>
					<el-form-item label="员工姓名">
						<el-input v-model="userInfo.nickName"></el-input>
					</el-form-item>
					<el-form-item label="员工权限"></el-form-item>
					<el-form-item label-width="0">
						<el-checkbox-group v-model="permissionSelected">
							<el-checkbox v-for="item in PERMISSION" :label="item.key"
								:key="item.key">{{item.name}}</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-button type="primary" @click="savePermission">保存</el-button>
				</el-form>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import {
		loadLabelClass,
		loadMemeberDetail,
		loadLabelDetail,
		editUser,
		loadUserCompanyPermission,
		updateUserCompanyPermission
	} from '@/api/index';
	export default {
		name: "userEdit",
		data() {
			return {
				activeName: 'base',
				userInfo: null,
				labelList: [],
				PERMISSION: [],
				permissionSelected: [],
			}
		},
		created() {
			this.loadLabelClass();
			this.loadMemeberDetail();
			this.loadUserCompanyPermission();
		},
		methods: {
			loadLabelClass: async function() {
				let result = await loadLabelClass();
				if (result.status == 1) {
					let item = result.data.list.filter(function(a) {
						return a.type == 4
					})
					result = await loadLabelDetail({
						labelId: item[0].id
					})
					if (result.status == 1) {
						for (let item of result.data.list) {
							item.all = false;
						}
						this.labelList = result.data.list;
					}
				}
			},
			loadMemeberDetail: async function() {
				let params = {
					userId: this.$route.query.id
				}
				let result = await loadMemeberDetail(params);
				if (result.status == 1) {
					result.data.labelList = result.data.labelList.map(obj => {
						return obj.labelId
					})
					this.userInfo = result.data;
				}
			},
			editUser: async function() {
				let params = {
					userId: this.userInfo.userId,
					nickName: this.userInfo.nickName,
					labelList: this.userInfo.labelList
				}
				console.log(params);
				let result = await editUser(params);
				if (result.status == 1) {
					this.$router.back();
				}
			},
			handleClick: function() {
				console.log('1')
			},
			handleCheckAllChange: function(val) {
				console.log('val', val.all)
				let userInfo = this.userInfo;
				let labelListBuff = val.labelList.map(obj => {
					return obj.itemId
				})
				console.log('labelListBuff', labelListBuff)
				if (val.all) {
					let list = [...labelListBuff, ...userInfo.labelList];
					userInfo.labelList = [...new Set(list)];
				} else {
					userInfo.labelList = userInfo.labelList.filter(function(a) {
						return labelListBuff.indexOf(a) < 0;
					})
				}
				console.log('userInfo', userInfo)
				this.userInfo = userInfo;
			},
			loadUserCompanyPermission: async function() {
				let params = {
					userId: this.$route.query.id
				}
				let result = await loadUserCompanyPermission(params);
				if (result.status == 1) {
					this.handlePermission(result.data.companyPermission);
				} else {
					this.$message({
						message: result.message,
						type: 'error',
						offset: 100
					});
				}
			},
			// 总厂
			handlePermission(PERMISSION) {
				let array = [];
				array.push({
					icon: '/packageUser/images/icon_staff_administration.png',
					selected: PERMISSION.userManage,
					name: '员工管理',
					key: 'userManage'
				})
				array.push({
					icon: '/packageUser/images/icon_shop_administration.png',
					selected: PERMISSION.goodsManage,
					name: '商品管理',
					key: 'goodsManage'
				})
				array.push({
					icon: '/packageUser/images/icon_me_finance.png',
					selected: PERMISSION.financeManage,
					name: '财务管理',
					key: 'financeManage'
				})
				array.push({
					icon: '/packageUser/images/icon_me_record_workers.png',
					selected: PERMISSION.recordManage,
					name: '工作进度',
					key: 'recordManage'
				})
				array.push({
					icon: '/packageUser/images/icon_me_manage_receiving.png',
					selected: PERMISSION.recordRecieve,
					name: '管理收货',
					key: 'recordRecieve'
				})
				array.push({
					icon: '/packageUser/images/icon_me_settlement.png',
					selected: PERMISSION.cropFinish,
					name: '完工结算',
					key: 'cropFinish'
				})
				array.push({
					icon: '/images/icon_label_post.png',
					selected: PERMISSION.recordOperate,
					name: '记工管理',
					key: 'recordOperate'
				})
				array.push({
					icon: '/packageUser/images/icon_tailoring_administration.png',
					selected: PERMISSION.cropManage,
					name: '裁剪管理',
					key: 'cropManage'
				})
				array.push({
					icon: '/images/icon_home_tailoring.png',
					selected: PERMISSION.cropRecord,
					name: '裁剪录入',
					key: 'cropRecord'
				})
				array.push({
					icon: '/packageUser/images/icon_price_administration.png',
					selected: PERMISSION.wagesManage,
					name: '工价管理',
					key: 'wagesManage'
				})
				array.push({
					icon: '/packageUser/images/icon_production_administration.png',
					selected: PERMISSION.productSum,
					name: '生产统计',
					key: 'productSum'
				})
				array.push({
					icon: '/packageUser/images/icon_work_administration.png',
					selected: PERMISSION.labelManage,
					name: '标签管理',
					key: 'labelManage'
				})
				array.push({
					icon: '/images/icon_home_record_workers_detailed.png',
					selected: PERMISSION.amountManage,
					name: '对账管理',
					key: 'amountManage'
				})
				array.push({
					icon: '/images/icon_home_tailoring_detailed.png',
					selected: PERMISSION.orderManage,
					name: '订单管理',
					key: 'orderManage'
				})
				array.push({
					icon: '/packageUser/images/icon_home_record_workers.png',
					selected: PERMISSION.exportManage,
					name: '出库管理',
					key: 'exportManage'
				})
				array.push({
					icon: '/images/iicon_me_recruit.png',
					selected: PERMISSION.recruitManage,
					name: '招聘管理',
					key: 'recruitManage'
				})
				this.PERMISSION = array;
				let permissionSelected = [];
				permissionSelected = array.filter(obj => {
					return obj.selected == 1
				})
				this.permissionSelected = permissionSelected.map(obj => {
					return obj.key
				})
			},
			savePermission: async function() {
				let PERMISSION = this.PERMISSION;
				let permissionSelected = this.permissionSelected;
				let permission = {};
				for (let item of PERMISSION) {
					permission[item.key] = 0;
					if (permissionSelected.some(function(a) {
							return a == item.key
						})) {
						permission[item.key] = 1;
					}
				}
				console.log(this.PERMISSION)
				console.log(this.permissionSelected)
				console.log(permission)
				let params = {
					permission,
					userId: this.$route.query.id
				}
				let result = await updateUserCompanyPermission(params)
				if (result.status == 1) {
					this.$router.back();
				} else {
					this.$message({
						message: result.message,
						type: 'error',
						offset: 100
					});
				}
			}
		}
	};
</script>

<style lang="scss">
	#userEdit {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.el-tabs {
			width: 96%;
			margin-top: 2%;

			.el-form {
				margin-top: 2%;

				.el-input {
					width: 300px;
				}

			}
		}
	}
</style>