export function isEmpty(value) {
	if (typeof value == 'string') {
		return value.trim() === '';
	} else if (typeof value == 'number') {
		return value === 0;
	} else {
		return value === null || value === undefined || value.length == 0;
	}
}
//获取当前时间
export function gouCurrentTime(timeStamp) {
	let c_time = new Date();
	if (timeStamp)
		c_time = new Date(timeStamp);
	let $_year = c_time.getFullYear();
	let $_month = c_time.getMonth() + 1;
	let $_day = c_time.getDate();
	let $_hour = c_time.getHours();
	let $_minute = c_time.getMinutes();
	let $_seconds = c_time.getSeconds();
	let $_week = c_time.getDay();
	let timeObject = {
		'year': '',
		'month': '',
		'day': '',
		'hour': '',
		'minute': '',
		'date': '',
		'time': '',
		'week': ''
	};
	if ($_month <= 9) {
		$_month = '0' + $_month;
	}
	if ($_day <= 9) {
		$_day = '0' + $_day
	}
	if ($_hour <= 9) {
		$_hour = '0' + $_hour;
	}
	if ($_minute <= 9) {
		$_minute = '0' + $_minute
	}
	if ($_seconds <= 9) {
		$_seconds = '0' + $_seconds;
	}
	switch ($_week) {
		case 0:
			timeObject.week = '天';
			break;
		case 1:
			timeObject.week = '一';
			break;
		case 2:
			timeObject.week = '二';
			break;
		case 3:
			timeObject.week = '三';
			break;
		case 4:
			timeObject.week = '四';
			break;
		case 5:
			timeObject.week = '五';
			break;
		case 6:
			timeObject.week = '六';
			break;
	}

	timeObject.year = $_year;
	timeObject.month = $_month;
	timeObject.day = $_day;
	timeObject.hour = $_hour;
	timeObject.minute = $_minute;
	timeObject.date = $_year + '-' + $_month + '-' + $_day;
	timeObject.time = $_hour + ':' + $_minute + ':' + $_seconds;
	timeObject.dTime = timeObject.date + " " + timeObject.time;
	timeObject.dTime1 = timeObject.date + " " + $_hour + ':' + $_minute;
	timeObject.year1 = $_year - 2000;

	return timeObject;

};
//根据年月获取当月最后一天
export function showMonthLastDayByMonth(date) {
	var Nowdate = new Date(date);
	var MonthNextFirstDay = new Date(Nowdate.getFullYear(), (Nowdate.getMonth() + 1), 1);
	var MonthLastDay = new Date(MonthNextFirstDay - 86400000);
	let M = Number(MonthLastDay.getMonth()) + 1
	if (parseInt(M) < 10) {
		M = '0' + M;
	}
	return MonthLastDay.getFullYear() + "-" + M + "-" + MonthLastDay.getDate();
}
//根据年月获取当月第一天
export function showMonthFirstDayByMonth(date) {
	var Nowdate = new Date(date);
	var MonthFirstDay = new Date(Nowdate.getFullYear(), (Nowdate.getMonth() + 0), 1);
	let M = Number(MonthFirstDay.getMonth()) + 1
	if (parseInt(M) < 10) {
		M = '0' + M;
	}
	return MonthFirstDay.getFullYear() + "-" + M + "-" + (parseInt(MonthFirstDay.getDate()) < 10 ? '0' + MonthFirstDay
		.getDate() : MonthFirstDay.getDate());
}
//静态文件远程地址
export function prefixAssetImage(url) {
	if (url[0] == 'h' && url[1] == 't' && url[2] == 't' && url[3] == 'p') {
		return url;
	} else if (url[0] == 'c' && url[1] == 'l' && url[2] == 'o' && url[3] == 'u') {
		return url;
	} else {
		var random = Math.floor(Math.random() * 100000000 + 1);
		return 'https://asset.linzhouzy.cn/' + url + "?random=" + random
	}
}