<template>
	<div id="sliderbar" v-if="device_type == 1">
		<el-menu class="el-menu-vertical-demo" background-color="#304156" text-color="#fff" active-text-color="#ffd04b">
			<el-submenu v-for="route in dynamic_routes" :key="route.path" :index="route.path">
				<template>
					<template slot="title">
						<span>{{route.meta.title}}</span>
					</template>
					<el-menu-item v-for="item in route.children" :key="item.path" :index="item.path">
						<router-link :to="resolvePath(route.path,item.path)">
							<div class="">
								<span>{{item.meta.title}}</span>
							</div>
						</router-link>
					</el-menu-item>
				</template>
			</el-submenu>
		</el-menu>
	</div>
	<div id="sliderbar_mobile" v-else-if="device_type == 2">
		<div class="item-view"></div>
		<div class="item-view"></div>
		<div class="item-view" @click="drawer = true"><span>我的工作台</span></div>
		<el-drawer class="el-drawer" title="我是标题" :visible.sync="drawer" direction="rtl">
			<el-menu class="el-menu-vertical-demo" background-color="#304156" text-color="#fff"
				active-text-color="#ffd04b">
				<el-submenu v-for="route in dynamic_routes" :key="route.path" :index="route.path">
					<template>
						<template slot="title">
							<span>{{route.meta.title}}</span>
						</template>
						<el-menu-item v-for="item in route.children" :key="item.path" :index="item.path">
							<router-link :to="resolvePath(route.path,item.path)">
								<div class="">
									<span>{{item.meta.title}}</span>
								</div>
							</router-link>
						</el-menu-item>
					</template>
				</el-submenu>
			</el-menu>
		</el-drawer>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		computed: {
			...mapGetters([
				'dynamic_routes',
				'device_type'
			])
		},
		data() {
			return {
				drawer: false
			}
		},
		methods: {
			resolvePath(baseUrl, routePath) {
				return `${baseUrl === '/' ? '' : baseUrl}/${routePath}`
			}
		}
	}
</script>

<style lang="scss">
	@import "@/style/color.scss";

	#sliderbar {
		width: 210px;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10000;
		background-color: $-main-bg-color;

		.el-menu-vertical-demo {
			border: none;
		}
	}

	#sliderbar_mobile {
		width: 100vw;
		height: 50px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10000;
		background-color: $-main-bg-color;
		display: flex;

		.item-view {
			display: flex;
			flex: 1;
			.el-menu-vertical-demo {
				border: none;
			}
		}
		.el-drawer{
		}
	}
</style>