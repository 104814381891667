<template>
	<div id="login">
		<span class="title" :style="{marginTop:`${marginTop}px`}">子阳工单系统</span>
		<div class="inputItem">
			<el-input placeholder="请输入账号" prefix-icon="el-icon-user-solid" v-model="phone">
			</el-input>
		</div>
		<div class="inputItem">
			<el-input placeholder="请输入密码" prefix-icon="el-icon-lock" v-model="pass" show-password>
			</el-input>
		</div>
		<div class="btn" @click="login()">
			<el-button type="primary" :loading="loading">登录</el-button>
		</div>
	</div>
</template>

<script>
	import {
		setToken,
		setPermission,
		setUserInfo
	} from '@/utils/auth';
	import {
		login,
		loadPermission,
		loadUserInfo
	} from '@/api/index'
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: "login",
		data() {
			return {
				phone: '13263338506', //13263338506
				pass: '838425', //838425
				loading: false
			}
		},
		computed: {
			...mapGetters([
				'device_type'
			]),
			marginTop: function() {
				let screenHeight = this.$getSystemInfo().screenHeight;
				let height = (screenHeight - 90 - 120 - 28) / 3;
				return height
			}
		},
		methods: {
			async login() {
				if (this.device_type == 2) {
					this.$message({
						message: '手机端暂未开放',
						type: 'error'
					});
					return;
				}
				this.loading = true;
				let params = {
					phone: this.phone,
					pass: this.pass
				}
				let result = await login(params);
				if (result.status == 1) {
					setToken(result.data.token);
					let result1 = await loadUserInfo();
					if (result1.status == 1) {
						setUserInfo(result1.data);
						let result2 = await loadPermission();
						if (result2.status == 1) {
							setPermission(result2.data)
							this.loading = false;
							// this.$message({
							// 	message: `登录成功`,
							// 	type: 'success'
							// });
							this.$router.replace({
								path: '/',
								query: {}
							})
						} else {
							this.loading = false;
							this.$message({
								message: result2.message,
								type: 'error'
							});
						}
					} else {
						this.loading = false;
						this.$message({
							message: result1.message,
							type: 'error'
						});
					}
				} else {
					this.loading = false;
					this.$message({
						message: result.message,
						type: 'error'
					});
				}
			}
		}
	};
</script>

<style lang="scss">
	@import "@/style/color.scss";

	#login {
		width: 100vw;
		height: 100vh;
		background-color: $-main-bg-color;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		@media screen and (max-width: 999px) {
			.title {
				font-size: 28px;
				color: #fff;
				margin-bottom: 40px;
			}

			.inputItem {
				width: 80%;
				background-color: #fff;
				border-radius: 10px;
				margin-bottom: 25px;
			}

			.btn {
				width: 80%;

				.el-button {
					width: 100%;
				}
			}
		}

		@media screen and (min-width: 1000px) {
			.title {
				font-size: 28px;
				line-height: 28px;
				color: #fff;
				margin-bottom: 20px;
				// margin-top: 300px;
			}

			.inputItem {
				width: 500px;
				height: 40px;
				background-color: #fff;
				border-radius: 10px;
				margin-bottom: 25px;
			}

			.btn {
				width: 500px;
				height: 40px;

				.el-button {
					width: 100%;
				}
			}
		}
	}
</style>