<template>
	<div id="checkAccount" v-loading.fullscreen="loading" element-loading-text="拼命加载中"
		element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
		<div class="pickerWrap">
			<span>当前月份：</span>
			<el-date-picker v-model="month" value-format="yyyy-MM" @change="monthChange" type="month"
				placeholder="请选择月份">
			</el-date-picker>
			<el-input style="width: 300px;margin-left: 2%;" placeholder="请输入床号/刀号搜索" prefix-icon="el-icon-search"
				v-model="params.searchKey" @input="searchTap">
			</el-input>
			<download-excel style="float: right;" :data="json_data" :fields="json_fields" worksheet="My Worksheet"
				:name="excelName">
				<el-button class="search-btn" type="primary" :disabled="downloadFlag">对账单下载</el-button>
			</download-excel>
		</div>
		<div class="content-table">
			<el-table :data="tableData.list" border style="width: 100%" :max-height="screenHeight"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" align="center" width="50">
				</el-table-column>
				<el-table-column align="center" prop="cropNo" label="床号(刀号)"></el-table-column>
				<el-table-column align="center" label="商品图">
					<template slot-scope="scope">
						<img :src="scope.row.goodsUrl" width="100" height="100" />
					</template>
				</el-table-column>
				<el-table-column align="center" label="商品">
					<template slot-scope="scope"><span
							style="color:crimson">{{scope.row.goodsList.length}}</span><span>款</span></template>
				</el-table-column>
				<el-table-column align="center" prop="packageTotal" label="数量"></el-table-column>
				<el-table-column align="center" prop="createTime" label="时间"></el-table-column>
				<!-- 	<el-table-column align="center" fixed="right" label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="primary" round size="small" @click="userEdit(scope)">详情</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</div>
		<el-pagination @size-change="sizeChange" @current-change="pageChange" :current-page="params.page"
			:page-sizes="[50,100, 200, 300, 400]" :page-size="50" layout="total, sizes, prev, pager, next"
			:total="tableData.totalCount" class="pagination"></el-pagination>
	</div>
</template>

<script>
	import {
		loadCompanyAmountCropList
	} from '@/api/index';
	import {
		gouCurrentTime,
		showMonthFirstDayByMonth,
		showMonthLastDayByMonth,
		isEmpty,
		prefixAssetImage
	} from '@/utils/util';
	export default {
		name: "financial",
		data() {
			return {
				params: {
					page: 1,
					size: 50,
					searchKey: ''
				},
				month: '',
				tableData: {
					list: [],
					totalCount: 0
				},
				json_fields: {
					'床号(刀号)': 'cropNo',
					'商品': 'goodsName',
					'货号': 'goodsNo',
					'尺码': 'sizeStr',
					'数量': 'packageTotal',
					'工价': 'processPrice',
					'金额': 'amountTotal'
				},
				json_data: [],
				excelName: '',
				downloadFlag: true
			}
		},
		computed: {
			screenHeight: function() {
				let screenHeight = this.$getSystemInfo().screenHeight;
				let height = screenHeight - 60 - 0.08 * screenHeight - 120;
				return height
			}
		},
		created() {
			this.month = `${gouCurrentTime().year}-${gouCurrentTime().month}`;
			this.excelName = `对账单(${this.month}).xls`;
			this.loadCompanyAmountCropList();
		},
		methods: {
			loadCompanyAmountCropList: async function() {
				this.loading = true;
				let params = this.params;
				params.startTime = `${showMonthFirstDayByMonth(this.month)} 00:00:00`;
				params.endTime = `${showMonthLastDayByMonth(this.month)} 23:59:59`;
				let result = await loadCompanyAmountCropList(params);
				if (result.status == 1) {
					for (let item of result.data.list) {
						item.goodsUrl = null;
						if (!isEmpty(item.goodsList[0].goodsHttpUrl))
							item.goodsUrl = prefixAssetImage(item.goodsList[0].goodsHttpUrl);
					}
					this.tableData = result.data;
				} else {
					this.$message({
						message: result.message,
						type: 'error',
						offset: 100
					});
				}
				this.loading = false;
				console.log(result, this.month);
			},
			handleSelectionChange(e) {
				console.log(e);
				let json_data = []
				let amountTotal1 = 0;
				for (let item of e) {
					for (let goodsItem of item.goodsList) {
						let amountTotal = parseFloat(parseFloat(goodsItem.processPrice || 0) * parseInt(goodsItem
							.packageTotal)).toFixed(2)
						amountTotal1 += parseFloat(amountTotal)
						let json_data_obj = {
							cropNo: item.cropNo,
							goodsName: goodsItem.goodsName,
							goodsNo: goodsItem.goodsNo,
							packageTotal: goodsItem.packageTotal,
							processPrice: goodsItem.processPrice,
							amountTotal: amountTotal
						}
						let sizeStr = '';
						for (let sizeItem of goodsItem.sizeList) {
							sizeStr += `${sizeItem.name}-`
						}
						sizeStr = sizeStr.substring(0, sizeStr.length - 1);
						json_data_obj.sizeStr = sizeStr;
						json_data.push(json_data_obj)
					}
				}
				if (e.length > 0) {
					json_data.push({
						cropNo: '总计',
						goodsName: '-',
						goodsNo: '-',
						packageTotal: '-',
						processPrice: '-',
						sizeStr: '-',
						amountTotal: amountTotal1
					})
					console.log(json_data);
					let downloadFlag = false;
					for (let item of json_data) {
						if (isEmpty(item.processPrice)) {
							this.$message({
								message: "请完善加工费",
								type: 'error',
								offset: 100
							});
							downloadFlag = true;
							break;
						}
					}
					this.downloadFlag = downloadFlag;
					this.json_data = json_data;
				}
			},
			sizeChange(val) {
				this.params.size = val;
				this.params.page = 1;
				this.loadCompanyAmountCropList();
			},
			pageChange(val) {
				this.params.page = val;
				this.loadCompanyAmountCropList();
			},
			searchTap() {
				console.log("searchTap")
				this.params.page = 1;
				this.loadCompanyAmountCropList();
			},
			monthChange: function(e) {
				this.month = e;
				this.tableData = {
					list: [],
					totalCount: 0
				}
				this.params.page = 1;
				this.excelName = `对账单(${e}).xls`;
				this.loadCompanyAmountCropList();
			}
		}
	};
</script>

<style lang="scss">
	@import "@/style/common.scss";

	.el-loading-spinner {}

	#checkAccount {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.pickerWrap {
			width: 96%;
			margin-top: 2%;
		}

		.content-table {
			width: 96%;
			margin-top: 2%;
		}

		.pagination {
			width: 96%;
			margin-top: 2%;
		}
	}
</style>