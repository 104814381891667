<template>
	<div id="product">
		<div class="content-table">
			<el-table :data="tableData.list" border style="width: 100%">
				<el-table-column align="center" prop="goodsName" label="商品"></el-table-column>
				<el-table-column align="center" label="图片">
					<template slot-scope="scope">
						<img :src="scope.row.goodsUrl" width="100" height="100" />
					</template>
				</el-table-column>
				<el-table-column align="center" prop="goodsNo" label="货号"></el-table-column>
				<el-table-column align="center" prop="finishedTotal" label="成品"></el-table-column>
				<el-table-column align="center" fixed="right" label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="primary" round size="small" @click="editGoods(scope)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-pagination @size-change="sizeChange" @current-change="pageChange" :current-page="params.page"
			:page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next"
			:total="tableData.totalCount" class="pagination"></el-pagination>
	</div>
</template>

<script>
	import {
		loadCompanyProduct
	} from '@/api/index';
	import {
		prefixAssetImage,
		isEmpty
	} from '@/utils/util';
	export default {
		name: "product",
		data() {
			return {
				params: {
					size: 10,
					page: 1
				},
				tableData: {
					totalCount: 10,
					list: []
				}
			}
		},
		created() {
			this.loadCompanyProduct();
		},
		methods: {
			loadCompanyProduct: async function() {
				let params = this.params;
				let result = await loadCompanyProduct(params);
				if (result.status == 1) {
					for (let item of result.data.list) {
						if (!isEmpty(item.goodsUrl))
							item.goodsUrl = prefixAssetImage(item.goodsUrl)
					}
					this.tableData = result.data;
				}
			},
			sizeChange(val) {
				this.params.size = val;
				this.params.page = 1;
				this.loadCompanyProduct();
			},
			pageChange(val) {
				this.params.page = val;
				this.loadCompanyProduct();
			},
		}
	}
</script>

<style lang="scss">
	#product {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.content-table {
			width: 96%;
			margin-top: 2%;
		}

		.pagination {
			width: 96%;
			margin: 2% 0;
		}
	}
</style>