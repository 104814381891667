<template>
	<div id="cropRecord">
		裁剪录入
	</div>
</template>

<script>
	export default {
		name: "cropRecord"
	};
</script>

<style lang="scss">
	#cropRecord {
		width: 100%;
	}
</style>
