import Vue from "vue";
import VueRouter from "vue-router";
import CONFIG from '@/utils/config';
import Layout from "@/views/Layout";
import Home from "@/views/Home";
import productManage from "@/views/Product/productManage";
import financialManage from "@/views/Financial/financialManage";
import cropManage from "@/views/Crop/cropManage";
import cropRecord from "@/views/Crop/cropRecord";
import recordManage from '@/views/Record/recordManage';
import userList from "@/views/User/userList";
import userEdit from "@/views/User/userEdit";
import checkAccountManage from '@/views/CheckAccount/checkManage';
import Login from "@/views/Login";

Vue.use(VueRouter);

export const staticRoutes = [{
	path: "/",
	name: CONFIG.ROUTENAMES.MAIN,
	component: Layout,
	redirect: '/home',
	meta: {
		title: '首页'
	},
	children: [{
		path: 'home',
		name: CONFIG.ROUTENAMES.MAINHOME,
		component: Home,
		meta: {
			title: '首页'
		},
	}]
}, {
	path: "/login",
	name: CONFIG.ROUTENAMES.LOGIN,
	component: Login,
	hidden: true,
	meta: {
		title: '登录'
	}
}];

export const dynamicRoutes = [{
		path: "/product",
		name: CONFIG.ROUTENAMES.PRODUCT,
		component: Layout,
		meta: {
			title: '产品中心'
		},
		children: [{
			path: 'productManage',
			name: CONFIG.ROUTENAMES.PRODUCTMANAGE,
			component: productManage,
			meta: {
				title: '产品列表'
			}
		}]
	},
	{
		path: "/crop",
		name: CONFIG.ROUTENAMES.CROP,
		component: Layout,
		meta: {
			title: '裁剪中心'
		},
		children: [{
			path: 'cropManage',
			name: CONFIG.ROUTENAMES.CROPMANAGE,
			component: cropManage,
			meta: {
				title: '裁剪管理'
			}
		}, {
			path: 'cropRecord',
			name: CONFIG.ROUTENAMES.CROPRECORD,
			component: cropRecord,
			hidden: true,
			meta: {
				title: '裁剪录入'
			}
		}]
	},{
		path: "/record",
		name: CONFIG.ROUTENAMES.RECORD,
		component: Layout,
		meta: {
			title: '记工中心'
		},
		children: [{
			path: 'recordManage',
			name: CONFIG.ROUTENAMES.RECORDMANAGE,
			component: recordManage,
			meta: {
				title: '记工管理'
			}
		}]
	},
	{
		path: "/financial",
		name: CONFIG.ROUTENAMES.FINANCIAL,
		component: Layout,
		meta: {
			title: '财务中心'
		},
		children: [{
			path: 'financialManage',
			name: CONFIG.ROUTENAMES.FINANCIALMANAGE,
			component: financialManage,
			meta: {
				title: '工资汇总'
			}
		}]
	},
	{
		path: "/checkAccount",
		name: CONFIG.ROUTENAMES.CHECKACCOUNT,
		component: Layout,
		meta: {
			title: '对账中心'
		},
		children: [{
			path: 'checkManage',
			name: CONFIG.ROUTENAMES.CHECKMANAGE,
			component: checkAccountManage,
			meta: {
				title: '对账管理'
			}
		}]
	},
	{
		path: "/user",
		name: CONFIG.ROUTENAMES.USER,
		component: Layout,
		meta: {
			title: '员工中心'
		},
		children: [{
			path: 'userList',
			name: CONFIG.ROUTENAMES.USERLIST,
			component: userList,
			meta: {
				title: '员工管理'
			}
		}, {
			path: 'userEdit',
			name: CONFIG.ROUTENAMES.USEREDIT,
			component: userEdit,
			hidden: true,
			meta: {
				title: '员工编辑'
			}
		}]
	},
	{
		path: '*',
		name: CONFIG.ROUTENAMES.OTHERS,
		redirect: '/',
		hidden: true
	}
]

const router = new VueRouter({
	routes: staticRoutes
});

export function resetRouter() {
	const newRouter = new VueRouter({
		routes: staticRoutes
	});
	router.matcher = newRouter.matcher // reset router
}

export default router;