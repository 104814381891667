<template>
	<div id="navbar">
		<div class="left-view">
			<img :src="userInfo.avatar" />
			<span>{{userInfo.phone}}</span>
		</div>
		<div class="right-view">
			<span class="company">{{userInfo.companyName}}</span>
			<el-button type="danger" @click="loginout">退出</el-button>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import store from '@/store';
	import {
		setToken
	} from '@/utils/auth';
	export default {
		computed: {
			...mapGetters([
				'device_type'
			])
		},
		props: {
			userInfo: {
				type: Object,
				required: true
			}
		},
		data() {
			return {

			}
		},
		methods: {
			loginout: async function() {
				setToken(null);
				await store.dispatch('user/loginout');
				this.$router.push("/login");
			}
		}
	}
</script>

<style lang="scss">
	@import "@/style/common.scss";

	#navbar {
		width: calc(100vw - 210px);
		height: $-main-navbar-height;
		position: fixed;
		top: 0;
		left: 210px;
		z-index: 9999;
		background-color: #fff;
		box-shadow: 0 10px 5px #ccc;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.left-view {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 20px;

			img {
				width: 45px;
				height: 45px;
				border-radius: 50%;
			}

			span {
				font-size: 15px;
				color: #333;
				font-weight: bold;
				margin-left: 10px;
			}
		}

		.right-view {
			margin-right: 20px;

			span.company {
				margin-right: 10px;
			}
		}
	}
</style>