//token有效期
module.exports.tokenExpire = 60 * 60 * 1000;

module.exports.ROUTENAMES = {
	MAIN:'MAIN',
	MAINHOME:'MAINHOME',
	
	LOGIN:'LOGIN',
	
	PRODUCT:'PRODUCT',
	PRODUCTMANAGE:'PRODUCTMANAGE',
	
	FINANCIAL:'FINANCIAL',
	FINANCIALMANAGE:'FINANCIALMANAGE',
	
	CROP:'CROP',
	CROPMANAGE:'CROPMANAGE',
	CROPRECORD:'CROPRECORD',
	
	RECORD:'RECORD',
	RECORDMANAGE:'RECORDMANAGE',
	
	CHECKACCOUNT:'CHECKACCOUNT',
	CHECKMANAGE:'CHECKMANAGE',
	
	USER:'USER',
	USERLIST:'USERLIST',
	USEREDIT:'USEREDIT',
	
	OTHERS:'OTHERS'
}

module.exports.STORAGEKEY = {
	LOGININFO:'LOGININFO',
	PERMISSION:'PERMISSION',
	USERINFO:'USERINFO'
}

module.exports.deviceMatch = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i